import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SEO from '../components/seo';
import LocalizedLink from '../components/localizedLink';
import useTranslations from '../components/useTranslations';
import ContactSection from '../components/Sections/ContactSection';

const useStyles = makeStyles((theme) => ({

  goBack: { transition: 'transform .3s ease-out' },
  goBackWrapper: {
    /* backgroundColor: 'red', */
    '&:hover $goBack': {
      color: 'green',
      right: '24px',
      transform: 'translate(10px, 0)',
    },
  },

}));

const NotFoundPage = ({ props }) => {
  const classes = useStyles();
  return (
    <>
      <ContactSection />
    </>
  );
};

export default NotFoundPage;
