import React, { useState } from 'react';

import classNames from 'classnames';
import { navigate } from 'gatsby';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import * as qs from 'query-string';

// @material-ui/icons

// core components
import Grid from '@material-ui/core/Grid';
import Recaptcha from 'react-google-recaptcha';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import styles from '../../assets/jss/material-kit-react/views/landingPageSections/workStyle';

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const stylesz = {
  ...styles,
  spaceBnameAemail: {
    padding: 12,
  },
};

const useStyles = makeStyles(stylesz);

export default function Contact(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const prefix = '';
  const classes = useStyles();

  const reset = () => {
    // console.log('reset');
    setName('');
    setEmail('');
    // setSubject('');
    setMessage('');
  };
  const handleChange = (e) => {
    // console.log(e.target.id);
    // console.log(e.target.value);
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      /* case 'subject':
        setSubject(e.target.value);
        break; */
      case 'message':
        setMessage(e.target.value);
        break;
      default:
    }
  };

  const handleRecaptcha = (value) => {
    // this.setState({ "g-recaptcha-response": value })
    setRecaptcha(value);
  };

  const handleSubmit = (e) => {
    if (!recaptcha) {
      return alert('reCaptcha required!');
    }
    e.preventDefault();
    // console.log('RECAPTCHA_KEY:', RECAPTCHA_KEY);

    // Set options for axios. The URL we're submitting to
    // (this.props.location.pathname) is the current page.
    /* const axiosOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(formData),
    }; */
    const form = e.target;
    const formData = {
      'form-name': form.getAttribute('name'),
      name,
      email,
      message,
      recaptcha,
    };
    const fetchOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(formData),
    };

    // Submit to Netlify. Upon success, set the feedback message and clear all
    // the fields within the form. Upon failure, keep the fields as they are,
    // but set the feedback message to show the error state.

    fetch('/', fetchOptions)
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));

    /* const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        name,
        email,
        message,
        recaptcha,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error)); */
  };

  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Work with us</h2>
          <h4 className={classes.description}>
            Divide details about your product or agency work into parts. Write a
            few lines about each one and contact us about any further
            collaboration. We will responde get back to you in a couple of
            hours.
          </h4>
          <form
            name="contact-recaptcha"
            method="post"
            action="/thanks"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact-recaptcha" />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.spaceBnameAemail}
              >
                <CustomInput
                  labelText="أكتب/ي إسمك"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    name: 'name',
                    value: name,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.spaceBnameAemail}
              >
                <CustomInput
                  labelText="أدخل/ي بريدك الإلكتروني"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    name: 'email',
                    value: email,
                  }}
                />
              </Grid>

              <CustomInput
                labelText="أكتب/ي رسالتك هنا"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  onChange: handleChange,
                  name: 'message',
                  value: message,
                }}
              />

              <Grid container justify="space-evenly">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.textCenter}
                >
                  <Recaptcha
                    sitekey={RECAPTCHA_KEY}
                    onChange={handleRecaptcha}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.textCenter}
                >
                  <Button color="primary" type="submit">
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
